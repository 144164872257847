.w-quote{
  .quote{
    @include col(12);
    padding: paddingFlex(s) 0;

    @include breakpoint(sm){
      padding: paddingFlex(m) 0;
    }

    @include breakpoint(md){
      padding: paddingFlex(l) 0;
    }

    &__content{
      position: relative;
      text-align: center;
      @include col(12);

      @include breakpoint(sm){
        @include col(10);
        @include offset(1);
      }

      @include breakpoint(md){
        @include col(6);
        @include offset(3);
      }

      .icon-quote{
        position: absolute;
        @include font-size(rem(40));
        color: $gray-03;

        @include breakpoint(md){
          @include font-size(rem(80));
        }

        &.quote-left{
          top: rem(-20);
          left: rem(18);

          @include breakpoint(md){
            top: rem(-10);
            left: rem(-10);
          }
        }

        &.quote-right{
          bottom: rem(-20);
          right: rem(18);
          transform: rotate(180deg);

          @include breakpoint(md){
            right: rem(-10);
            bottom: rem(-45);
          }
        }
      }

      .title{
        margin-bottom: rem(20);
        @include col(10);
        @include offset(1);
        position: relative;

        @include breakpoint(md){
          @include col(12);
          @include offset(0);
          margin-top: rem(30);
        }
      }

      .credit {
        display: flex;
        align-items: center;
        margin-top: rem(20);

        @include breakpoint(md) {
          margin-top: rem(30);
          display: block;
        }

        .avatar {
          height: rem(85);
          width: rem(85);
          background-position: top;
          background-size: cover;
          border-radius: 50%;

          @include breakpoint(md) {
            position: absolute;
            left: rem(-100);
            top: 50%;
            transform: translateY(-50%);
          }
        }

        .author{
          margin-left: rem(15);
          color: $gray-01;
          width: 60%;

          @include breakpoint(md) {
            margin-left: 0;
          }
        }
      }

      .wysiwyg{
        position: relative;

        p{
          @include p-m;
        }
      }
    }

    &.avatar{
      .quote__content{
        text-align: left;

        .quote-left{
          left: rem(12);
          top: rem(-15);

          @include breakpoint(sm) {
            left: 0;
          }

          @include breakpoint(md){
            top: rem(-15);
            left: rem(-40);
          }
        }

        .quote-right{
          bottom: rem(80);

          @include breakpoint(md){
            right: rem(-20);
            bottom: rem(10);
          }
        }

        .title{
          width: 100%;
          margin-left: 0;
          padding: 0;
        }
      }
    }
  }

  &.bg{
    background: $tertiary-color;
    color: $white;

    @include breakpoint(sm){
      background: transparent;
      position: relative;

      &:after{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 25%;
        height: 100%;
        background: $tertiary-color;
        z-index: -1;
      }
    }

    .quote{
      @include breakpoint(sm){
        background: $tertiary-color;
      }

      &__content{
        .icon-quote{
          &:before {
            color: rgba($white, 0.2);
          }
        }

        .author{
          color: $white;
        }
      }
    }
  }
}
