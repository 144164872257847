@import './../cards/faq-item';

.w-list-faq{
    .list-faq{
        @include col(12);
        
        @include breakpoint(md){
            @include col(8);
            @include offset(2);
        }
        
        .title{
            margin-bottom: rem(20);
        }
        
        .subtitle{
            margin-bottom: rem(40);
        }
    }
}
