.w-title-text{
  .title-text{
    @include col(12);

    @include breakpoint(sm) {
      @include col(10);
      @include offset(1);
    }

    @include breakpoint(md) {
      display: flex;
    }

    &__left{
      margin-bottom: rem(40);

      @include breakpoint(md) {
        @include col(4,10);
        margin-bottom: 0;
      }
    }

    &__right{
      @include breakpoint(md) {
        @include col(6,10);
      }
    }
  }
}