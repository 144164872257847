.w-two-columns{
  position: relative;

  .two-columns{
    padding: paddingFlex(s) 0;
    @include col(12);

    @include breakpoint(sm) {
      padding: paddingFlex(m) 0;
      @include col(10);
      @include offset(1);
    }

    @include breakpoint(md) {
      padding: paddingFlex(l) 0;
      display: flex;
      @include col(10);
    }

    &__left{
      margin-bottom: rem(40);

      @include breakpoint(md) {
        @include col(6);
        margin-bottom: 0;
        padding-right: rem(45);
      }
    }

    &__right{
      @include breakpoint(md) {
        @include col(6);
        padding-left: rem(45);
      }
    }
  }

  &.bg{
    background: $light-primary-color;

    @include breakpoint(md) {
      background: transparent;

      &:before{
        content: '';
        position: absolute;
        width: 25%;
        height: 100%;
        top: 0;
        left: 0;
        background: $light-primary-color;
        z-index: -1;
      }
    }

    .container{
      @include breakpoint(md) {
        background: $light-primary-color;
      }
    }
  }
}