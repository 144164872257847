.faq-item{
  margin-bottom: rem(20);
  cursor: pointer;
    border: rem(1) solid $gray-02;
    border-radius: rem(4);
    overflow: hidden;
  &:last-child{
    margin-bottom: 0;
  }

  @include breakpoint(sm){
    position: relative;
  }

  &__content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    padding: rem(15);
    background: $white;
    @include transition_default;
    @include breakpoint(sm) {
      padding: rem(30);
    }
    
    .icon {
      font-size: rem(24);
        @include transition-default;
        
        .open & {
            color: $white;
            transform: rotate(-180deg);
        }
    }
    
    .open & {
      background: $secondary-color;
    }
  }

  &__text{
    &__bottom{
      display: flex;
      align-items: center;

      @include breakpoint(sm) {
        margin-bottom: 0;
      }

      .icon{
        @include font-size(rem(40));
        margin-right: rem(10);
        color: $tertiary-color;
          font-size: rem(24);
        @include transition_default;

        @include breakpoint(sm){
          position: absolute;
          top: calc(50% - 20px);
          left: rem(30);
        }
      }

      .title-line{
        .title{
          @include font-size(rem(20), rem(24));
          @extend %font-primary-bold;
          color: $tertiary-color;
          @include transition_default;
            
            .open & {
                color: $white;
            }
        }
      }
    }
  }

  .btn{
    &:hover{
      &:before{
        background: transparent;
      }
    }
  }

  &:hover{
    @include breakpoint(md){
      .faq-item__content{
        background: rgba(22,12,92,0.15);
      }
    }
  }
    
    &__wysiwyg {
        padding: rem(20) rem(20) rem(30);
    }
}
