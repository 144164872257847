.w-cta{
  .cta{
    @include col(12);
    text-align: center;

    @include breakpoint(sm){
      @include col(10);
      @include offset(1);
    }

    @include breakpoint(md){
      @include col(6);
      @include offset(3);
    }

    .title{
      margin-bottom: rem(40);

      @include breakpoint(md){
        margin-bottom: rem(30);
        display: inline-block;
      }
    }

    .text{
      + .btn{
        margin-top: rem(40);

        @include breakpoint(md){
          margin-top: rem(30);
        }
      }
    }
  }

  &.bg{
    background-image: url('../../static/img/biologiste-left.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: rem(-250) center;

    @include breakpoint(sm){
      background-size: 90%;
      background-position: rem(-250) center;
    }

    @include breakpoint(md){
      background-size: contain;
      background-position: rem(-25) center;
    }

    @include breakpoint(lg){
      background-position: left center;
    }
  }
}