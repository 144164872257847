.list-item{
    margin-bottom: rem(20);
    cursor: pointer;
    &:last-child{
        margin-bottom: 0;
    }

    @include breakpoint(sm){
        position: relative;
    }

    &__text__wrapper{
        display: flex;
        flex-direction: row;
    }

    .accordion__image{
        margin-right: rem(20);
        overflow: hidden;
    }

    &.has-image{
        .list-item__content{
            @include breakpoint(sm) {
                padding: rem(20) rem(30);
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }

    &__content{
        display: block;
        text-decoration: none;
        border: rem(1) solid $gray-02;
        border-radius: rem(4);
        padding: rem(15) rem(15) rem(20);
        background: $white;
        @include transition_default;

        @include breakpoint(sm) {
            padding: rem(20) rem(30);
            padding-left: rem(85);
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__text{
        &__top{
            display: flex;
            align-items: flex-end;
            margin-bottom: rem(15);

            @include breakpoint(sm) {
                margin-bottom: rem(10);
            }

            .src{
                @extend %b;
                color: $gray-01;
                @include transition_default;
            }

            .date{
                text-transform: capitalize;
                color: $gray-01;
                margin-left: rem(10);
                @include transition_default;
            }
        }

        &__bottom{
            margin-bottom: rem(15);
            display: flex;
            align-items: center;

            @include breakpoint(sm) {
                margin-bottom: 0;
            }

            .icon{
                @include font-size(rem(40));
                margin-right: rem(10);
                color: $secondary-color;
                @include transition_default;

                @include breakpoint(sm){
                    position: absolute;
                    top: calc(50% - 20px);
                    left: rem(30);
                }
            }

            .title-line{
                .title{
                    @include font-size(rem(20), rem(24));
                    @extend %font-primary-bold;
                    color: $primary-color;
                    @include transition_default;
                }

                .weight{
                    color: $gray-01;
                    vertical-align: text-bottom;
                    margin-left: rem(10);
                    @include transition_default;
                }
            }
        }
    }

    .btn{
        &:hover{
            &:before{
                background: transparent;
            }
        }
    }

    &:hover{
        @include breakpoint(md){
            .list-item__content{
                background: $tertiary-color;

                .list-item__text{
                    &__top{
                        .src{
                            color: $white;
                        }

                        .date{
                            color: $white;
                        }
                    }

                    &__bottom{
                        .icon{
                            color: $white;
                        }

                        .title-line{
                            .title{
                                color: $white;
                            }

                            .weight{
                                color: $white;
                            }
                        }
                    }
                }

                .btn{
                    color: $white !important;
                    border-color: $white !important;
                }
            }
        }
        .path1{
            &:before{
                color: $white !important;
            }
        }
        .path2, .path3, .path4, .path5{
            @include transition-default;
            &:before{
                color: $secondary-color !important;
            }
        }
    }
}

.path1{
    @include transition-default;
    &:before{
        color: $secondary-color !important;
    }
}
