/* vietnamese */
@font-face {
    font-family: 'Muli';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('Muli Italic'), local('Muli-Italic'), url(https://fonts.gstatic.com/s/muli/v16/7Au-p_0qiz-afTf2IALT4EYFOGEH.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Muli';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('Muli Italic'), local('Muli-Italic'), url(https://fonts.gstatic.com/s/muli/v16/7Au-p_0qiz-afTf2IQLT4EYFOGEH.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Muli';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('Muli Italic'), local('Muli-Italic'), url(https://fonts.gstatic.com/s/muli/v16/7Au-p_0qiz-afTf2LwLT4EYFOA.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
    font-family: 'Muli';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local('Muli Bold Italic'), local('Muli-BoldItalic'), url(https://fonts.gstatic.com/s/muli/v16/7Au9p_0qiz-afTf-lCfGwmQIElsO02Ex.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Muli';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local('Muli Bold Italic'), local('Muli-BoldItalic'), url(https://fonts.gstatic.com/s/muli/v16/7Au9p_0qiz-afTf-lCfGw2QIElsO02Ex.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Muli';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local('Muli Bold Italic'), local('Muli-BoldItalic'), url(https://fonts.gstatic.com/s/muli/v16/7Au9p_0qiz-afTf-lCfGzWQIElsO0w.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
    font-family: 'Muli';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Muli Regular'), local('Muli-Regular'), url(https://fonts.gstatic.com/s/muli/v16/7Auwp_0qiz-afT3GLQjUwkQ1OQ.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Muli';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Muli Regular'), local('Muli-Regular'), url(https://fonts.gstatic.com/s/muli/v16/7Auwp_0qiz-afTzGLQjUwkQ1OQ.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Muli';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Muli Regular'), local('Muli-Regular'), url(https://fonts.gstatic.com/s/muli/v16/7Auwp_0qiz-afTLGLQjUwkQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
    font-family: 'Muli';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Muli Bold'), local('Muli-Bold'), url(https://fonts.gstatic.com/s/muli/v16/7Au_p_0qiz-adYnjOCr2z24PMFk-0g.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Muli';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Muli Bold'), local('Muli-Bold'), url(https://fonts.gstatic.com/s/muli/v16/7Au_p_0qiz-adYnjOCv2z24PMFk-0g.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Muli';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Muli Bold'), local('Muli-Bold'), url(https://fonts.gstatic.com/s/muli/v16/7Au_p_0qiz-adYnjOCX2z24PMFk.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
