@import "~dauphine/mixins";
@import "../../core/helpers/variables";
@import "../../core/helpers/functions";
@import "../../core/mixins/mixins";

.w-labs {
    position: relative;
    margin-bottom: rem(40);

    @include breakpoint(sm) {
        margin-bottom: rem(60);
    }

    @include breakpoint(lg) {
        margin-bottom: rem(80);
    }

    .map__redirect {
        position: relative;
        background: $gray-01;
        width: 100%;
        height: rem(300);
        margin-bottom: rem(40);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &:after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 999;
            cursor: pointer;
        }

        @include breakpoint(sm) {
            position: absolute;
            top: rem(-30);
            width: calc((720px / 12) * 6 + (720px / 12) / 2);
            height: rem(400);
            left: ((-720px / 12) * 6 + (-720px / 12) - 24px * 2);
        }
        @include breakpoint(md) {
            height: rem(465);
            width: calc((940px / 12) * 6 - 24px);
            left: ((-940px / 12) * 6 + (-940px / 12));
        }
        @include breakpoint(lg) {
            height: rem(530);
            width: calc((1200px / 12) * 6 + (1200px / 12) / 2 + 24px * 2);
            left: ((-1200px / 12) * 6 + (-1200px / 12 - 24px * 5));
        }

        .labs__map {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            .map--disabled {
                display: flex;
                align-items: center;
                flex-direction: column;
                p {
                    color: $white;
                }

                .btn {
                    color: $white !important;
                    border-color: $white !important;
                    margin-top: rem(20);
                    &:hover {
                        background: rgba($white, 0.2);
                    }
                    &:before {
                        &:hover {
                            background: none;
                        }
                    }
                }
            }
        }
    }

    .labs__content {
        position: relative;
        width: 100%;
        @include breakpoint(sm) {
            @include offset(7, 12);
            padding: rem(70) 0;
            @include breakpoint(md) {
                @include offset(6, 12);
            }
            @include breakpoint(md) {
                padding: rem(100) 0;
            }
            @include breakpoint(lg) {
                @include offset(7, 12);
            }
        }

        .labs__content-txt {
            padding: 0 rem(12);
            @include breakpoint(sm) {
                padding: 0;
            }
            .labs-heading {
                margin-bottom: rem(15);
                @include breakpoint(sm) {
                    margin-bottom: rem(20);
                }
                @include breakpoint(md) {
                    max-width: 80%;
                }
            }
        }
    }
}

//Google map components
.gm-style-iw-c {
    border-radius: 0 !important;
    padding: rem(25) !important;
    &:before {
        content: "";
        width: rem(4);
        height: rem(33);
        position: absolute;
        top: rem(20);
        left: 0;
        background: $secondary-color;
    }
}
.gm-style-iw-d {
    overflow: auto !important;
}
.gm-ui-hover-effect {
    top: 0 !important;
    right: 0 !important;
    img {
        width: rem(18) !important;
        height: rem(18) !important;
    }
}

.gm-bundled-control-on-bottom {
    display: none !important;
    z-index: 99999 !important;
    @include breakpoint(sm) {
        top: rem(40) !important;
        display: block !important;
    }
}

.gm-ui-hover-effect {
    display: none !important;
    @include breakpoint(sm) {
        display: block !important;
        color: $gray-01 !important;
    }
}

.pac-container {
    height: rem(60);
    overflow-y: scroll;
    @include breakpoint(sm) {
        height: rem(80);
    }
}

.infowindow {
    @extend %font-primary-regular;
    &__title {
        @include font-size(rem(16), rem(25));
        font-weight: 700;
        margin-bottom: rem(5);
    }
    &__address,
    &__numbers {
        @include font-size(rem(14), rem(22));
        display: flex;
        align-items: center;
        width: 100%;
        i {
            color: $gray-02;
            font-size: rem(15);
            margin-right: rem(9);
        }
    }
    &__address {
        margin-bottom: rem(5);
    }
    &__numbers {
        justify-content: space-between;
    }
    &__phone,
    &__fax {
        display: flex;
        align-items: center;
    }
    &__phone {
        margin-right: rem(15);
    }
    &__cta {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: rem(20);
    }
    &__cta--fiche {
        + * {
            margin-left: rem(16);
        }
    }
    &__cta--rdv {
        @include p;
        a {
            display: flex;
            align-items: center;
            text-decoration: none;
            color: $primary-color;
        }
        i {
            color: $secondary-color;
            font-size: rem(20);
            margin-right: rem(9);
        }
    }
    .icon-close {
        position: absolute;
        top: rem(14);
        right: rem(14);
        color: $gray-01;
        cursor: pointer;
        &:before {
            font-size: rem(20);
        }
        @include breakpoint(sm) {
            display: none;
        }
    }
}
