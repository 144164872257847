.w-news-list {
  position: relative;
  padding-top: paddingFlex(s);

  @include breakpoint(sm) {
    padding-top: paddingFlex(m);
  }

  @include breakpoint(md) {
    padding-top: paddingFlex(l);
  }

  &:before{
    content: '';
    width: 100%;
    background: $gray-03;
    position: absolute;
    left: 0;
    bottom: 0;
    height: rem(220);

    @include breakpoint(sm){
      height: rem(290);
    }

    @include breakpoint(md){
      height: rem(310);
    }

    @include breakpoint(lg){
      height: rem(415);
    }
  }

  .news-list {
    width: 100%;

    &__title{
      @include col(12, 12);

      @include breakpoint(sm){
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      @include breakpoint(md){
        @include col(12, 12, false);
      }

      .title{
        margin-bottom: rem(40);

      }

      .news-list-btn{
        margin-bottom: rem(20);
        border-color: $tertiary-color;
        color: $tertiary-color !important;
        &:hover{
          background: $tertiary-color;
          color: $white !important;
        }
        @include breakpoint(sm){
          margin-bottom: rem(40);
        }
      }
    }

    &__content {
      @include col(12, 12, false);
      padding: 0;
      display: flex;
      flex-wrap: wrap;

      @include breakpoint(sm){
        @include col(12, 12, false);
      }
    }

    &.flex, &.single {
      @include breakpoint(sm) {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          height: rem(1);
          width: 100%;
          top: - paddingFlex(l);
          left: 0;
          background: $gray-03;
        }
      }

      .news-list__title{
        @include breakpoint(md){
          @include col(11);
          @include offset(1);
          padding: 0;
        }
      }
    }
  }
}
