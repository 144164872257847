@import '~dauphine/mixins';
@import '../../core/helpers/variables';
@import '../../core/helpers/functions';
@import '../../core/mixins/mixins';

@import '~slick-carousel/slick/slick';
@import '~slick-carousel/slick/slick-theme';

@import './_home-header';
@import './_home-service';
@import './_home-header-alt';
@import '../../components/news/news-list';
@import '../../components/news/news-item';
@import '../../components/flexible-contents/flexible-content';

html,body{
  overflow-x: hidden;
}

.home{
  .w-labs{
    position: relative;

    @include breakpoint(sm){
      &:before{
        content: '';
        position: absolute;
        bottom: rem(-180);
        right: 0;
        height: rem(400);
        width: 100%;
        background-image: url('../../static/img/biologiste.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: calc(100% + 70px) center;
      }
    }

    @include breakpoint(md){
      &:before{
        bottom: rem(-230);
        height: rem(500);
        background-position: calc(100% + 40px) center;
      }
    }

    @include breakpoint(lg){
      &:before{
        bottom: rem(-310);
        height: rem(650);
        background-position: right center;
      }
    }

    .labs__content{
      position: relative;
    }
  }
}
