.home-service {

    background: $tertiary-color;
    padding-top: rem(85);
    @include breakpoint(md) {
        padding-bottom: rem(85);
    }

    .row {
        align-items: center;
        position: relative;
    }

    &__bloc {
        position: relative;
    }

    &__icon {
        color: $white;
        font-size: rem(36);
        background: $secondary-color;
        padding: rem(20);
        border-radius: 50%;
        display: inline-block;
        position: absolute;

        @include breakpoint-max(md) {
            top: 0;
            left: rem(20);
            transform: translateY(-50%);
        }

        @include breakpoint(md) {
            top: 50%;
            left: 0;
            transform: translateX(-50%) translateY(-50%);
        }

        &--custom {
            width: rem(76);
            height: rem(76);
            //position: relative;
            overflow: hidden;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                max-width: 100%;
                height: auto;
            }
        }
    }

    &__title {
        + * {
            margin-top: rem(20);
        }
    }

    &__text {
        + * {
            margin-top: rem(30);
        }
    }

    &__left {
        @include flex-col(12);

        @include breakpoint(md) {
            @include flex-col(5);
        }

        @include breakpoint(lg) {
            @include offset(1,12,left);
        }

        .home-service {

            &__bloc {
                @include breakpoint-max(md) {
                    padding-top: rem(60);
                }
            }

            &__icon {
                transform: translateY(-50%) translateX(-24);
                @include breakpoint-min(md) {
                    transform: translateY(-50%) translateX(calc(-100% - 24px));
                }
            }

            &__title,
            &__text p {
                color: $white;
            }
        }

        @include breakpoint-max(md) {
            + * {
                margin-top: rem(80);
            }
        }
    }

    &__right {
        @include flex-col(12);

        @include breakpoint-max(md) {
            transform: translateY(40px);
        }

        @include breakpoint(md) {
            @include flex-col(6);
            @include offset(1,12,left);

            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        @include breakpoint(lg) {
            @include flex-col(5);
        }

        .home-service__bloc {
            background: $white;
            box-shadow: $news-shadow;
            padding: rem(20);

            @include breakpoint-max(md) {
                padding-top: rem(50);
            }

            @include breakpoint(md) {
                padding: rem(60);
            }

            + * {
                margin-top: rem(60);
                @include breakpoint(md) {
                    margin-top: rem(30);
                }
            }
        }
    }

    &--overflow {
        + * {
            padding-top: rem(130);
        }
    }
}
