.w-img{
  .img-block{
    @include col(12);

    @include breakpoint(sm){
      @include col(10);
      @include offset(1);
    }

    @include breakpoint(md){
      @include col(8);
      @include offset(2);
    }

    &__image{
      img{
        box-shadow: $img-shadow;
      }
    }

      &__video {
          width: 100%;
          position: relative;
          padding-top: calc(9*100%/16);
          > * {
              max-width: 100%;
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
          }
      }
  }
}
