@import "./img-full";
@import "./img";
@import "./txt-img";
@import "./wysiwyg";
@import "./title-text";
@import "./two-columns";
@import "./numbers";
@import "./icons";
@import "./cta";
@import "./cta-blocks";
@import "./quote";
@import "./list-files";
@import "./list-links";
@import "./list-team";
@import "./list-faq";
@import "../../components/news/news-list";
@import "../../components/news/news-item";
@import "../../components/map/map";

.w-flexible-content {
    padding-bottom: paddingFlex(s);

    @include breakpoint(sm) {
        padding-bottom: paddingFlex(m);
    }

    @include breakpoint(md) {
        padding-bottom: paddingFlex(l);
    }

    .w-flex:last-child {
        margin-bottom: -paddingFlex(s);

        @include breakpoint(sm) {
            margin-bottom: -paddingFlex(m);
        }

        @include breakpoint(md) {
            margin-bottom: -paddingFlex(l);
        }
    }

    .w-txt-img,
    .w-title-text,
    .w-img,
    .w-cta,
    .w-list-files,
    .w-list-links,
    .w-list-team,
    .w-list-faq,
    .w-linked-labs {
        padding: paddingFlex(s) 0;

        @include breakpoint(sm) {
            padding: paddingFlex(m) 0;
        }

        @include breakpoint(md) {
            padding: paddingFlex(l) 0;
        }
    }
}
