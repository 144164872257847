.home-header--alt {

    .slick-prev,
    .slick-next {
        bottom: rem(20);
        background: transparent;
        border: 1px solid $white;
        @include breakpoint(sm) {
            left: calc(((100vw - 720px) / 2) + 15px);
        }
        @include breakpoint(md) {
            left: calc( (100% / 12) + 40px );
        }
    }

    .slick-prev {
        border-right: none;
    }

    &__content {

    }

    &__texts {

        background: transparent;
        padding-bottom: rem(100);
        @include breakpoint(md) {

            margin-left: 0;
            width: auto;

            @include flex-col(6, 12, '0 0');
            padding-left: calc(100% / 12);
        }

        .header-title,
        .header-txt,
        .header-txt p{
            color: $white;
        }
    }

    &__img {
        @include breakpoint-max(md) {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
        }
        width: 100%;
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            height: 100%;
            width: 100%;
            opacity: 0.5;
            background: linear-gradient(90deg, $secondary-color 0%, transparent 57.85%),
                        linear-gradient(180deg, $secondary-color 0%, transparent 100%);
        }
    }

    &__services-container {
        @include breakpoint-max(md) {
            background: $tertiary-color;
            padding-top: rem(80);
            padding-bottom: rem(40);
        }
        @include breakpoint(md) {
            position: absolute;
            bottom: rem(-80);
            right: 0;
            width: 50%;
        }
    }

    &__services {

        background: transparent;
        @include breakpoint(md) {

            @include flex-col(5, 6, '0 0');
            @include offset(1,6,left);
        }

        .row {
            align-items: center;
            position: relative;
        }

        &__bloc {
            position: relative;
            background: $white;
            @include breakpoint-max(md) {
                padding: rem(60) rem(20) rem(20);
            }
            padding: rem(60);
            box-shadow: 0 10px 30px -10px rgba(0,0,0, 0.15);
        }

        &__bloc + &__bloc {
            margin-top: rem(60);
            @include breakpoint-max(md) {
                margin-top: rem(60);
            }
        }

        &__icon {
            color: $white;
            font-size: rem(36);
            background: $secondary-color;
            padding: rem(20);
            border-radius: 50%;
            display: inline-block;
            position: absolute;

            @include breakpoint-max(md) {
                top: 0;
                left: rem(20);
                transform: translateY(-50%);
            }

            @include breakpoint(md) {
                top: 50%;
                left: 0;
                transform: translateX(-50%) translateY(-50%);
            }

            &--custom {
                width: rem(76);
                height: rem(76);
                //position: relative;
                overflow: hidden;

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-width: 100%;
                    height: auto;
                }
            }
        }

        &__title {
            + * {
                margin-top: rem(20);
            }
        }

        &__text {
            + * {
                margin-top: rem(30);
            }
        }

        &__left {
            @include flex-col(12);

            @include breakpoint(md) {
                @include flex-col(5);
            }

            @include breakpoint(lg) {
                @include offset(1, 12, left);
            }

            .home-service {

                &__bloc {
                    @include breakpoint-max(md) {
                        padding-top: rem(60);
                    }
                }

                &__icon {
                    transform: translateY(-50%) translateX(-24);
                    @include breakpoint-min(md) {
                        transform: translateY(-50%) translateX(calc(-100% - 24px));
                    }
                }

                &__title,
                &__text p {
                    color: $white;
                }
            }

            @include breakpoint-max(md) {
                + * {
                    margin-top: rem(80);
                }
            }
        }

        &__right {
            @include flex-col(12);

            @include breakpoint-max(md) {
                transform: translateY(40px);
            }

            @include breakpoint(md) {
                @include flex-col(6);
                @include offset(1, 12, left);

                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }

            @include breakpoint(lg) {
                @include flex-col(5);
            }

            .home-service__bloc {
                background: $white;
                box-shadow: $news-shadow;
                padding: rem(20);

                @include breakpoint-max(md) {
                    padding-top: rem(50);
                }

                @include breakpoint(md) {
                    padding: rem(60);
                }

                + * {
                    margin-top: rem(60);
                    @include breakpoint(md) {
                        margin-top: rem(30);
                    }
                }
            }
        }

        &--overflow {
            + * {
                padding-top: rem(130);
            }
        }
    }

}

.home-header--alt + .main {
    .w-flexible-content {
        padding-top: rem(40);
    }
}
