.news-item{
  @include col(12);
  margin-bottom: rem(40);

  @include breakpoint(sm){
    @include col(4);
    margin-bottom: rem(60);
  }

  @include breakpoint(md){
    @include col(4, 12, false);
    margin-bottom: rem(80);
    padding: 0 rem(8);


    &:nth-child(3n+3){
      padding-right: 0;
      padding-left: rem(16);

      @include breakpoint(lg){
        padding-left: rem(36);
      }
    }

    &:nth-child(3n+1){
      padding-left: 0;
      padding-right: rem(16);

      @include breakpoint(lg){
        padding-right: rem(36);
      }
    }
  }

  @include breakpoint(lg){
    padding: 0 rem(18);
  }

  &__content{
    position: relative;
    text-decoration: none;
    background: $white;
    box-shadow: $news-shadow;
    display: flex;
    flex-direction: column;
    height: 100%;
    @include transition_default;
  }

  &__date{
    position: absolute;
    height: rem(70);
    width: rem(70);
    background: $tertiary-color;
    color: $white;
    @extend %font-primary-bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    .day{
      @include font-size(rem(30));
    }

    .month{
      @include font-size(rem(16));
      text-transform: uppercase;
    }
  }

  &__img{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &:after {
      content: "";
      padding-top: 67%;
      height: 0;
      display: block;
    }
  }

  &__text{
    padding: rem(20);
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;

    @include breakpoint(md){
      padding: rem(30);

      &:before{
        content:"";
        width: 100%;
        height: 0;
        position: absolute;
        left: 0;
        bottom: 0;
        background: $secondary-color;
        transition : all 0.2s cubic-bezier(0.04, 0.25, 0.59, 0.98);
      }
    }

    .title{
      @include font-size(rem(21), rem(25));
      @extend %font-headings-bold;
      color: $primary-color;
      position: relative;
      max-width: 100%;
      margin-bottom: rem(20);
      @include transition_default;

      @include breakpoint(md){
        @include font-size(rem(24), rem(29));
      }
    }

    .link{
      position: relative;
      align-self: flex-end;
    }
  }

  &__content {
    @include breakpoint(md) {
      &:hover {
        transform: translateY(rem(-10));

        .news-item__text {
          &:before {
            height: 100%;
          }

          .title {
            color: $white;
          }

          .link {
            color: $white !important;

            &:before {
              color: $white;
            }
          }
        }
      }
    }
  }
}
