.home-header{
  position: relative;
  overflow: hidden;

    .slick-prev,
    .slick-next {
        left: rem(12);
        bottom: rem(300);
        @include breakpoint(md) {
            left: 0;
            bottom: rem(90);
        }
        @include breakpoint(sm) { left: calc(((100vw - 720px) / 2) + 40px); }
        @include breakpoint(md) { left: calc(((100vw - 940px) / 2) + 60px); }
        @include breakpoint(lg) { left: calc(((100vw - 1200px) / 2) + 60px); }
        @include breakpoint(xlg) { left: calc(((100vw - 1400px) / 2) + 60px); }
        top: auto;
        right: auto;
        background: $secondary-color;
        height: rem(40);
        width: rem(40);
        display: inline-block;
        z-index: 1;
        @include transition-default;
        &:before {
            //background: $secondary-color;
            opacity: 1;
        }
        &:hover, &:focus {
            background: $gray-01;
        }
    }
    .slick-prev {
        transform: none;
        &:before {
            @include icon($icon-arrowback, $white, rem(14));
        }
    }
    .slick-next {
        transform: translateX(100%);
        box-shadow: inset 1px 0 0 rgba($white, 0.5);
        &:before {
            @include icon($icon-arrowright, $white, rem(14));
        }
    }

    .slick-slide {
        display: flex;
        > div {
            width: 100%;
        }
    }

  &__txt{
    position: relative;
    display: flex !important;

    @include breakpoint-max(md){
        flex-direction: column-reverse;
    }

    @include breakpoint(md){
      padding: rem(200) 0 rem(85) 0;
    }

    @include breakpoint(lg){
      padding: rem(260) 0 rem(90) 0;
    }

      &.aos-animate{
      &:before{
        transform: scaleY(1);
      }
    }
  }

    &__texts {

        border-left: 1px solid $gray-02;
        padding-left: rem(40);

        @include breakpoint-max(md) {
            padding-top: rem(50);
            padding-bottom: rem(50);
            background: $white;
        }

        @include breakpoint(md) {
            padding-left: rem(60);
            padding-bottom: rem(65);
        }

        @include breakpoint-max(md) {
            padding-left: rem(12);
            padding-right: rem(12);
            max-width: rem(720);
            margin-left: auto;
            margin-right: auto;
        }

        width: 100%;
        //@include breakpoint(sm) { width: calc((5 * 720px / 12) + ((100vw - 720px) / 2)); }
        @include breakpoint(md) { width: calc((5 * 940px / 12) + ((100vw - 940px) / 2)); }
        @include breakpoint(lg) { width: calc((5 * 1200px / 12) + ((100vw - 1200px) / 2)); }
        @include breakpoint(xlg) { width: calc((4 * 1400px / 12) + ((100vw - 1400px) / 2)); }
        margin-left: 0;
        //@include breakpoint(sm) { margin-left: calc(((100vw - 720px) / 2)); }
        @include breakpoint(md) { margin-left: calc(((100vw - 940px) / 2)); }
        @include breakpoint(lg) { margin-left: calc(((100vw - 1200px) / 2)); }
        @include breakpoint(xlg) { margin-left: calc(((100vw - 1400px) / 2)); }


        .header-title, .header-txt{
            margin-bottom: rem(15);

            @include breakpoint(sm){
                margin-bottom: rem(40);
            }

            @include breakpoint(md){
                margin-bottom: rem(20);
            }

            @include breakpoint(lg){
                margin-bottom: rem(45);
            }
        }

        .header-title{
            color: $tertiary-color;
        }

        .header-txt{
            > p {
                @include p;
                @include breakpoint(sm){
                    @include p-m;
                }
                @include breakpoint(lg){
                    @include p-l;
                }
            }
        }
    }

  &__img{

    overflow: hidden;
    width: 100%;
    @include breakpoint-max(md) {
      display: block;
      width: 100%;
      height: rem(320);
        position: relative;
    }
    @include breakpoint(md) {
        position: absolute;
        top: 0;
        right: 0;
    }
    @include breakpoint(md) { width: calc((7 * 940px / 12) + ((100vw - 940px) / 2)); }
    @include breakpoint(lg) { width: calc((7 * 1200px / 12) + ((100vw - 1200px) / 2)); }
    height: 100%;
    z-index: -1;


    img{
        position: absolute;
        &.square,
        &.portrait {
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            height: auto;
        }

        &.landscape {
            @include breakpoint-max(md) {
                top: 0;
            }
            @include breakpoint-min(md) {
                bottom: 0;
                height: 100%;
            }
            min-height: 100%;
            width: auto;
            min-width: 100%;
            max-width: none;
            right: 50%;
            transform: translateX(50%);
        }
    }
  }
}
