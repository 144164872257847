.w-wysiwyg{
  position: relative;

  .wysiwyg-block{
    padding: paddingFlex(s) 0;
    @include col(12);

    @include breakpoint(sm){
      padding: paddingFlex(m) 0;
      @include col(10);
      @include offset(1);
    }

    @include breakpoint(md){
      padding: paddingFlex(l) 0;
      display: flex;
      flex-direction: column;
      @include col(6);
      @include offset(3);
    }

    &__title{
      margin-bottom: rem(40);

      @include breakpoint(md){
        margin-bottom: rem(30);
      }
    }
  }

  &.bg{
    background: $light-primary-color;

    @include breakpoint(md) {
      background: transparent;

      &:before{
        content: '';
        position: absolute;
        width: 25%;
        height: 100%;
        top: 0;
        left: 0;
        background: $light-primary-color;
        z-index: -1;
      }
    }

    .container{
      @include breakpoint(md) {
        background: $light-primary-color;
      }
    }
  }
}