@import './../cards/cta-block';

.w-cta-blocks{
  padding: paddingFlex(s) 0;

  @include breakpoint(sm) {
    padding: paddingFlex(m) 0;
  }

  @include breakpoint(md) {
    padding: paddingFlex(l) 0;
    position: relative;

    &:before{
      content: '';
      position: absolute;
      width: 14%;
      height: 100%;
      top: 0;
      left: 0;
      background: $tertiary-color;
    }
  }

  @include breakpoint(lg) {
    &:before{
      width: 19%;
    }
  }

  @include breakpoint(xlg) {
    &:before{
      width: 22%;
    }
  }

  .cta-blocks{
      width: 100%;
    &__title{
      margin-bottom: rem(40);
      @include col(12);

      @include breakpoint(md) {
        @include col(6);
        @include offset(3);
      }
    }

    &__content{
      @include breakpoint(sm) {
        display: flex;
      }
    }
  }
}
