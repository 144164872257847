.w-txt-img{
  .txt-img{
    @include col(12);

    @include breakpoint(sm) {
      @include col(10);
      @include offset(1);
    }

    @include breakpoint(md) {
      display: flex;
      flex-direction: row;
    }

    &__text{
      @include breakpoint(md) {
        @include col(6);
      }

      .title{
        margin-bottom: rem(40);
      }

      .wysiwyg{
        margin-bottom: rem(40);
      }

      .btn{
        margin-bottom: rem(40);
      }
    }

    &__img{
      position: relative;
      text-align: center;

      @include breakpoint(md) {
        @include col(6);
      }

        .img-before {
            &.isvideo {
                width: 100%;
                position: relative;
                padding-top: calc(9*100%/16);
                > * {
                    max-width: 100%;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                }
            }
        }

      &.before {
        .img-before {
          position: relative;

          &:before {
            content: '';
            position: absolute;
            width: rem(65);
            height: rem(120);
            background: $secondary-color;
            z-index: -1;
            right: rem(-12);
            top: rem(-15);

            @include breakpoint(sm) {
              right: rem(-15);
            }

            @include breakpoint(md) {
              width: rem(75);
              height: rem(140);
            }
          }

          .img {
            box-shadow: $img-shadow;
            width: 100%;
            height: auto;
          }
        }
      }
    }

    &.img-left{
      flex-direction: row-reverse;

      @include breakpoint(md) {
        @include col(11);
      }

      .txt-img__text{
        @include breakpoint(md) {
          @include col(5,11);
          @include offset(1);
        }
      }

      .txt-img__img{
        @include breakpoint(md) {
          @include col(5,11);
          padding-right: rem(60);
          padding-left: 0;
        }

        .img-before {
          &:before {
            @include breakpoint(md) {
              left: rem(-30);
              bottom: rem(-30);
              right: auto;
              top: auto;
            }
          }
        }
      }
    }

    &.img-right{
      .txt-img__img{
        @include breakpoint(md) {
          padding-left: rem(60);
        }

        .img-before {
          &:before {
            @include breakpoint(md) {
              right: rem(-30);
              top: rem(-30);
            }
          }
        }
      }
    }
  }
}
