/* ===================
	    Fonts
/* ===================*/
%font-primary-regular{ font-family:$font-primary; font-weight:400; }
%font-primary-bold{ font-family:$font-primary; font-weight:700; }

%font-secondary-regular{ font-family:$font-secondary; font-weight:400; }
%font-secondary-bold{ font-family:$font-secondary; font-weight:700; }

%font-headings-regular{ font-family:$font-headings; font-weight:400; }
%font-headings-bold{ font-family:$font-headings; font-weight:700; }


/* ===================
	    Icomoon
/* ===================*/

@mixin icon($content, $color: $primary-color, $size: rem(24)) {
    content: $content;
    font-family: "icomoon";
    font-size: $size;
    color: $color;
    font-weight: 400;
}

%img-homothetic-init {
    position: relative;
    overflow: hidden;
    &:after{
        content: "";
        display: block;
    }
}
