.w-list-team{
  .list-team{
    width: 100%;

    &__title{
      @include col(12);
      margin-bottom: rem(40);

      @include breakpoint(md){
        @include col(11);
        @include offset(1);
        padding: 0;
      }
    }

    &__members{
      @include breakpoint(sm){
       display: flex;
      }

      .member{
        @include col(12);
        margin-bottom: rem(20);

        @include breakpoint(sm){
          @include col(3);
          margin-bottom: 0;
        }

        &:last-child{
          margin-bottom: 0;
        }

        &__content{
          position: relative;
          background: $white;
          box-shadow: $news-shadow;
          display: flex;
          flex-direction: column;
          height: 100%;
          @include transition_default;
        }

        &__img{
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;

          &:after {
            content: "";
            padding-top: 100%;
            height: 0;
            display: block;
          }
        }

        &__text{
          padding: rem(20);
          position: relative;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          align-items: flex-start;
          height: 100%;

          @include breakpoint(md){
            padding: rem(30);

            &:before{
              content:"";
              width: 100%;
              height: 0;
              position: absolute;
              left: 0;
              bottom: 0;
              background: $secondary-color;
              transition : all 0.2s cubic-bezier(0.04, 0.25, 0.59, 0.98);
            }
          }

          .titles {
            margin-bottom: rem(25);

            .title {
              @include font-size(rem(21), rem(25));
              @extend %font-headings-bold;
              color: $primary-color;
              position: relative;
              @include transition_default;

              @include breakpoint(md) {
                @include font-size(rem(24), rem(29));
              }
            }
          }

          .links{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;

            .contact{
              text-decoration: none;
              color: $secondary-color;
              @extend %font-primary-bold;
              display: flex;
              align-items: center;
              @include transition_default;

              .icon{
                @include font-size(rem(24));
                margin-right: rem(5);
              }

              &:hover{
                opacity: 0.7;
              }
            }
          }
        }
      }
    }
  }
}
