@mixin p{
  color: $text-color;
  @include font-size(rem(16), rem(25));
}

@mixin p-s{
  @include font-size(rem(14), rem(22));
}

@mixin p-m{
  @include font-size(rem(20), rem(29));
}

@mixin p-l{
  @include font-size(rem(24), rem(36));
}