.w-numbers{
  padding: paddingFlex(s) 0 0;

  @include breakpoint(sm) {
    padding: paddingFlex(m) 0;
  }

  @include breakpoint(md) {
    padding: paddingFlex(l) 0;
  }

  &__title{
    .numbers-title{
      @include col(12);

      @include breakpoint(md) {
        @include col(10);
        @include offset(1);
      }

      &__title{
        margin-bottom: rem(40);
      }
    }
  }

  &__content{
    background: $tertiary-color;

    @include breakpoint(sm) {
      background: transparent;
      position: relative;

      &:before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 25%;
        height: 100%;
        background: $secondary-color;
      }
    }

    .numbers{
      @include col(12);
      padding: paddingFlex(s) 0;
      position: relative;
      overflow: hidden;

      @include breakpoint(sm) {
        padding: paddingFlex(m) 0;
      }

      @include breakpoint(md) {
        padding: paddingFlex(l) 0;
      }

      &:before{
        content: '';
        position: absolute;
        top: 0;
        left: rem(12);
        width: 100%;
        height: 100%;
        background: $tertiary-color;
      }

      &__values{
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        z-index: 2;

        @include breakpoint(md) {
          @include col(10);
          @include offset(1);
          flex-direction: row;
          justify-content: space-between;
        }

        .number{
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: rem(40);

          @include breakpoint(md) {
            margin-bottom: 0;
          }

          &:last-child{
            margin-bottom: 0;
          }

          &__value{
            color: $white;
            @extend %font-primary-bold;
            @include font-size(rem(45));
            margin-bottom: rem(20);
          }

          &__description{
            color: $white;
            @include p-m;
          }
        }
      }
    }
  }
}
