$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../../static/fonts/icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-add: "\e900";
$icon-addlocation: "\e901";
$icon-arrowback: "\e902";
$icon-arrowbotom: "\e903";
$icon-arrowright: "\e904";
$icon-arrowup: "\e905";
$icon-building: "\e906";
$icon-bullet: "\e907";
$icon-cards: "\e908";
$icon-chat: "\e909";
$icon-check: "\e90a";
$icon-close: "\e90b";
$icon-comments: "\e90c";
$icon-creditcard: "\e90d";
$icon-date: "\e90e";
$icon-delete: "\e90f";
$icon-direction: "\e910";
$icon-doc: "\e911";
$icon-doc-1: "\e912";
$icon-download: "\e913";
$icon-edit: "\e914";
$icon-electric: "\e915";
$icon-euros: "\e916";
$icon-eye: "\e917";
$icon-eye-blind: "\e918";
$icon-fax: "\e919";
$icon-filter: "\e91a";
$icon-home: "\e91b";
$icon-img: "\e91c";
$icon-info: "\e91d";
$icon-lightbulb: "\e91e";
$icon-link: "\e91f";
$icon-list: "\e920";
$icon-lock: "\e921";
$icon-mail: "\e922";
$icon-menu: "\e923";
$icon-miniarrowbottom: "\e924";
$icon-miniarrowleft: "\e925";
$icon-miniarrowright: "\e926";
$icon-miniarrowup: "\e927";
$icon-minicards: "\e928";
$icon-minimize: "\e929";
$icon-more: "\e92a";
$icon-mylocation: "\e92b";
$icon-navigation: "\e92c";
$icon-parking: "\e92d";
$icon-pause: "\e92e";
$icon-pdf: "\e92f";
$icon-phone: "\e930";
$icon-place: "\e931";
$icon-play: "\e932";
$icon-pmr: "\e933";
$icon-question: "\e934";
$icon-quote: "\e935";
$icon-reload: "\e936";
$icon-satisfaction: "\e937";
$icon-schedule: "\e938";
$icon-search: "\e939";
$icon-share: "\e93a";
$icon-facebook: "\e93b";
$icon-instagram: "\e93c";
$icon-linkedin: "\e93d";
$icon-pinterest: "\e93e";
$icon-twitter: "\e93f";
$icon-viadeo: "\e940";
$icon-vimeo: "\e941";
$icon-youtube: "\e942";
$icon-star: "\e943";
$icon-star-empty: "\e944";
$icon-star-half: "\e945";
$icon-touch: "\e946";
$icon-work: "\e947";
$icon-zip-path1: "\e948";
$icon-zip-path2: "\e949";
$icon-zip-path3: "\e94a";
$icon-zip-path4: "\e94b";
$icon-zip-path5: "\e94c";

