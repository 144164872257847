@import "../fonts/_font-titillium";
@import "../fonts/_font-montserrat";
@import "../fonts/_font-ubuntu";
@import "../fonts/_font-source-sans-pro";
@import "../fonts/_font-raleway";
@import "../fonts/_font-pt-sans";
@import "../fonts/_font-roboto-slab";
@import "../fonts/_font-lato";
@import "../fonts/_font-muli";
@import "../fonts/_font-open-sans";
@import "../fonts/_font-nunito";
@import "../fonts/_font-work-sans";
@import "../fonts/_font-karla";
@import "../fonts/_font-lora";
@import "../fonts/_font-roboto";
@import "../fonts/_font-signika";

@import "../fonts/_font-icomoon";

/* ===================
	    Colors
/* ===================*/

$primary-color      : #2D2A29;
$secondary-color    : var(--secondary-color);
$tertiary-color     : var(--tertiary-color);
$text-color         : $primary-color;

$white              : #FFFFFF;
$gray-01            : #8A817E;
$gray-02            : #C7C2C1;
$gray-03            : #EDE9E8;

$success-color      : #009D0C;
$warning-color      : #FBAA30;
$error-color        : #D31212;

$grey-bg            : #F3F2F2;

$light-white: rgba(255, 255, 255, 0.5);
$light-white-2: rgba(255, 255, 255, 0.2);
$light-primary-color: #F3F2F2;

$gradient-primary   : linear-gradient(135deg, #00ABC5 48.62%, #0072C5 100%);
$gradient-secondary : linear-gradient(135deg, #584F4C 0%, #2D2A29 100%);

$blue-bg-gradient-sm : linear-gradient(90.61deg, var(--secondary-color) 20%, transparent 100%);
$blue-bg-gradient-md : linear-gradient(90.61deg, var(--secondary-color) 45%, transparent 100%);
$blue-bg-gradient-lg : linear-gradient(90.61deg, var(--secondary-color) 50%, transparent 100%);
$blue-bg-gradient-lab : linear-gradient(90.61deg, var(--secondary-color) 60%, transparent 100%);

$gray-bg-gradient-sm : linear-gradient(90.61deg, $gray-03 20%, transparent 100%);
$gray-bg-gradient-md : linear-gradient(90.61deg, $gray-03 45%, transparent 100%);
$gray-bg-gradient-lg : linear-gradient(90.61deg, $gray-03 50%, transparent 100%);
$gray-bg-gradient-lab : linear-gradient(90.61deg, $gray-03 60%, transparent 100%);

$menu-shadow: 0 rem(6) rem(20) rgba(0, 0, 0, 0.15);
$news-shadow: 0 rem(10) rem(30) rem(-4) rgba(0, 0, 0, 0.15);
$img-shadow: 0 rem(10) rem(15) rem(-4) rgba($primary-color, 0.25);

/* ===================
	    Fonts
/* ===================*/
$font-primary :     var(--font-primary), 'Titillium Web',-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif;
$font-secondary :   'Montserrat', -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif;
$font-headings :    var(--font-headings), 'Titillium Web',-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif;

/*
Ubuntu - Source sans pro
Raleway - Pt sans
Roboto Slab - Lato
Lato - Lato
Muli - Muli
Open Sans - Nunito
Work Sans - Karla
Lora - Roboto
Signika - Lato
 */


/* ===================
    Margin & padding
/* ===================*/
$paddings : (
  s : rem(4),
  m : rem(8),
  l : rem(12)
);

@function padding($varname) {
  @return map-get($paddings, $varname);
};
/* ===================
    Config
/* ===================*/
$debug-rem : true;

/* ===================
    Dauphine var default
/* ===================*/
$columns: true;
$sizing: true;

$grid: false;
$order: false;
$direction: false;
$display: false;
$spacing: false;

/* ===================
    FlexContent
/* ===================*/
$paddingFlex: (
      s : rem(40),
      m : rem(60),
      l : rem(80)
);

@function paddingFlex($varname) {
  @return map-get($paddingFlex, $varname);
};
