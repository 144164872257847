@import "./_var-icomoon";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?3yklfv');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?3yklfv#iefix') format('embedded-opentype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?3yklfv') format('truetype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?3yklfv') format('woff'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?3yklfv##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add {
  &:before {
    content: $icon-add;
  }
}
.icon-addlocation {
  &:before {
    content: $icon-addlocation;
  }
}
.icon-arrowback {
  &:before {
    content: $icon-arrowback;
  }
}
.icon-arrowbotom {
  &:before {
    content: $icon-arrowbotom;
  }
}
.icon-arrowright {
  &:before {
    content: $icon-arrowright;
  }
}
.icon-arrowup {
  &:before {
    content: $icon-arrowup;
  }
}
.icon-building {
  &:before {
    content: $icon-building;
  }
}
.icon-bullet {
  &:before {
    content: $icon-bullet;
  }
}
.icon-cards {
  &:before {
    content: $icon-cards;
  }
}
.icon-chat {
  &:before {
    content: $icon-chat;
  }
}
.icon-check {
  &:before {
    content: $icon-check;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-comments {
  &:before {
    content: $icon-comments;
  }
}
.icon-creditcard {
  &:before {
    content: $icon-creditcard;
  }
}
.icon-date {
  &:before {
    content: $icon-date;
  }
}
.icon-delete {
  &:before {
    content: $icon-delete;
  }
}
.icon-direction {
  &:before {
    content: $icon-direction;
  }
}
.icon-doc {
  &:before {
    content: $icon-doc;
  }
}
.icon-doc-1 {
  &:before {
    content: $icon-doc-1;
  }
}
.icon-download {
  &:before {
    content: $icon-download;
  }
}
.icon-edit {
  &:before {
    content: $icon-edit;
  }
}
.icon-electric {
  &:before {
    content: $icon-electric;
  }
}
.icon-euros {
  &:before {
    content: $icon-euros;
  }
}
.icon-eye {
  &:before {
    content: $icon-eye;
  }
}
.icon-eye-blind {
  &:before {
    content: $icon-eye-blind;
  }
}
.icon-fax {
  &:before {
    content: $icon-fax;
  }
}
.icon-filter {
  &:before {
    content: $icon-filter;
  }
}
.icon-home {
  &:before {
    content: $icon-home;
  }
}
.icon-img {
  &:before {
    content: $icon-img;
  }
}
.icon-info {
  &:before {
    content: $icon-info;
  }
}
.icon-lightbulb {
  &:before {
    content: $icon-lightbulb;
  }
}
.icon-link {
  &:before {
    content: $icon-link;
  }
}
.icon-list {
  &:before {
    content: $icon-list;
  }
}
.icon-lock {
  &:before {
    content: $icon-lock;
  }
}
.icon-mail {
  &:before {
    content: $icon-mail;
  }
}
.icon-menu {
  &:before {
    content: $icon-menu;
  }
}
.icon-miniarrowbottom {
  &:before {
    content: $icon-miniarrowbottom;
  }
}
.icon-miniarrowleft {
  &:before {
    content: $icon-miniarrowleft;
  }
}
.icon-miniarrowright {
  &:before {
    content: $icon-miniarrowright;
  }
}
.icon-miniarrowup {
  &:before {
    content: $icon-miniarrowup;
  }
}
.icon-minicards {
  &:before {
    content: $icon-minicards;
  }
}
.icon-minimize {
  &:before {
    content: $icon-minimize;
  }
}
.icon-more {
  &:before {
    content: $icon-more;
  }
}
.icon-mylocation {
  &:before {
    content: $icon-mylocation;
  }
}
.icon-navigation {
  &:before {
    content: $icon-navigation;
  }
}
.icon-parking {
  &:before {
    content: $icon-parking;
  }
}
.icon-pause {
  &:before {
    content: $icon-pause;
  }
}
.icon-pdf {
  &:before {
    content: $icon-pdf;
  }
}
.icon-phone {
  &:before {
    content: $icon-phone;
  }
}
.icon-place {
  &:before {
    content: $icon-place;
  }
}
.icon-play {
  &:before {
    content: $icon-play;
  }
}
.icon-pmr {
  &:before {
    content: $icon-pmr;
  }
}
.icon-question {
  &:before {
    content: $icon-question;
  }
}
.icon-quote {
  &:before {
    content: $icon-quote;
    color: #ede9e8;
  }
}
.icon-reload {
  &:before {
    content: $icon-reload;
  }
}
.icon-satisfaction {
  &:before {
    content: $icon-satisfaction;
  }
}
.icon-schedule {
  &:before {
    content: $icon-schedule;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-share {
  &:before {
    content: $icon-share;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram;
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin;
  }
}
.icon-pinterest {
  &:before {
    content: $icon-pinterest;
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;
  }
}
.icon-viadeo {
  &:before {
    content: $icon-viadeo;
  }
}
.icon-vimeo {
  &:before {
    content: $icon-vimeo;
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube;
  }
}
.icon-star {
  &:before {
    content: $icon-star;
  }
}
.icon-star-empty {
  &:before {
    content: $icon-star-empty;
  }
}
.icon-star-half {
  &:before {
    content: $icon-star-half;
  }
}
.icon-touch {
  &:before {
    content: $icon-touch;
  }
}
.icon-work {
  &:before {
    content: $icon-work;
  }
}
.icon-zip .path1 {
  &:before {
    content: $icon-zip-path1;
    color: rgb(0, 0, 0);
  }
}
.icon-zip .path2 {
  &:before {
    content: $icon-zip-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-zip .path3 {
  &:before {
    content: $icon-zip-path3;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-zip .path4 {
  &:before {
    content: $icon-zip-path4;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-zip .path5 {
  &:before {
    content: $icon-zip-path5;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}